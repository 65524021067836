/* at the very least you must set these two display properties */
/* this is so you can use CSS animations when toggling results */


/* visual styles */
.searchwp-live-search-results {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	background: #fff;
	-webkit-box-shadow: 0 0 2px 0 rgba(30,30,30,0.4);
	box-shadow: 0 0 2px 0 rgba(30,30,30,0.4);
	border-radius: 3px;
	width: 315px;
	min-height: 175px;
	max-height: 300px;
}

.searchwp-live-search-no-min-chars .spinner {
	opacity: 0.3;
}

.searchwp-live-search-result p {
	font-size:0.9em;
	padding:1em;
	margin:0;
	border-bottom:1px solid rgba(30,30,30,0.1);
}

.searchwp-live-search-result--focused {
	background: #DDD;
}

.searchwp-live-search-result--focused a {
	color: #000;
}

.searchwp-live-search-result:last-of-type p {
	border-bottom:0;
}

.searchwp-live-search-result a {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Bullet proof Screen Reader Only text */
.searchwp-live-search-instructions {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

@keyframes searchwp-spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes searchwp-spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes searchwp-spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}
