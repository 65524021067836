// /*------------------------------------*\
// 	# TABLE OF CONTENTS
// \*------------------------------------*/

// Useful mixins like clearfix.
@import "mixins/mixins-master";

@import "variables-settings/color-settings";                // Default
@import "variables-settings/bootstrap-settings";            // Default
@import "variables-settings/global-variables";              // Default

@import "variables-settings/bootstrap-maps-override";       // Boostrap Custom
@import "../../node_modules/bootstrap/scss/maps";                              // Bootstrap Maps
@import "../../node_modules/bootstrap/scss/mixins";                            // Default

// Imports entire bootstrap lib in one line
// @import "../../node_modules/bootstrap/scss/bootstrap";

/* = UTILITIES
..................................*/
@import "../../node_modules/bootstrap/scss/utilities";                         // Default
@import "../../node_modules/bootstrap/scss/utilities/api";                     // Default


/* = LAYOUT and COMPONENTS
..................................*/
@import "../../node_modules/bootstrap/scss/root";                              // CSS Variables
@import "../../node_modules/bootstrap/scss/reboot";                            // Default
@import "../../node_modules/bootstrap/scss/type";                              // Default
@import "../../node_modules/bootstrap/scss/images";                            // Optional
@import "../../node_modules/bootstrap/scss/containers";                        // Default
@import "../../node_modules/bootstrap/scss/grid";                              // Default
@import "../../node_modules/bootstrap/scss/tables";                            // Default
@import "../../node_modules/bootstrap/scss/forms";                             // Optional
@import "../../node_modules/bootstrap/scss/buttons";                           // Optional
@import "../../node_modules/bootstrap/scss/transitions";                       // Optional
@import "../../node_modules/bootstrap/scss/dropdown";                          // Optional
@import "../../node_modules/bootstrap/scss/button-group";                      // Optional
@import "../../node_modules/bootstrap/scss/nav";                               // Optional
@import "../../node_modules/bootstrap/scss/navbar";                            // Optional
@import "../../node_modules/bootstrap/scss/card";                              // Optional
@import "../../node_modules/bootstrap/scss/accordion";                         // Optional
@import "../../node_modules/bootstrap/scss/breadcrumb";                        // Optional
@import "../../node_modules/bootstrap/scss/pagination";                        // Optional
@import "../../node_modules/bootstrap/scss/badge";                             // Optional
@import "../../node_modules/bootstrap/scss/alert";                             // Optional
@import "../../node_modules/bootstrap/scss/progress";                          // Optional
@import "../../node_modules/bootstrap/scss/list-group";                        // Optional
@import "../../node_modules/bootstrap/scss/close";                             // Optional
@import "../../node_modules/bootstrap/scss/toasts";                            // Optional
@import "../../node_modules/bootstrap/scss/modal";                             // Optional
@import "../../node_modules/bootstrap/scss/tooltip";                           // Optional
@import "../../node_modules/bootstrap/scss/popover";                           // Optional
@import "../../node_modules/bootstrap/scss/carousel";                          // Optional
@import "../../node_modules/bootstrap/scss/spinners";                          // Optional
@import "../../node_modules/bootstrap/scss/offcanvas";                         // Optional
@import "../../node_modules/bootstrap/scss/placeholders";                      // Optional

/* = HELPERS
..................................*/
@import "../../node_modules/bootstrap/scss/helpers/clearfix";                  // Optional
@import "../../node_modules/bootstrap/scss/helpers/color-bg";                  // Optional
@import "../../node_modules/bootstrap/scss/helpers/colored-links";             // Optional
@import "../../node_modules/bootstrap/scss/helpers/ratio";                     // Optional
@import "../../node_modules/bootstrap/scss/helpers/position";                  // Optional
@import "../../node_modules/bootstrap/scss/helpers/stacks";                    // Optional
@import "../../node_modules/bootstrap/scss/helpers/visually-hidden";           // Optional
@import "../../node_modules/bootstrap/scss/helpers/stretched-link";            // Optional
@import "../../node_modules/bootstrap/scss/helpers/text-truncation";           // Optional
@import "../../node_modules/bootstrap/scss/helpers/vr";                        // Optional

@import "../../node_modules/swiper/swiper-vars.scss";       // Swiper JS
@import "../../node_modules/swiper/swiper.scss";
@import "../../node_modules/swiper/modules/pagination.scss";
@import "../../node_modules/swiper/modules/navigation.scss";

@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss"; // BS Icons

@import "searchwp/base"; // SearchWP
@import "searchwp/forms";
@import "searchwp/results-page";

@import "extends";
