.form-block {
  &:not(.form-block--dark) {
    .gform_button.btn.btn-form-submit {
      @extend .btn-outline-primary;
    }
  }
  &.form-block--dark {
    .gform_button.btn.btn-form-submit {
      @extend .btn-outline-light;
    }
  }
}

.nav {
  &.navbar-upper {
    @extend .row-cols-1;
    @include bp($nv) {
      >* {
        flex: 0 0 auto;
        width: 50%;
      }
    }
    @include bp(900px) {
      >* {
        flex: 0 0 auto;
        width: 25%;
      }
    }
  }
}

.footer__middle-row.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @include bp(0 $nv) {
    flex-flow: column;
    >* {
      flex: 0 0 auto;
      width: 100%;
    }
    .row.justify-content-center .col-48 {
      width: 50% !important;
    }
  }
  @include bp($nv) {
    >* {
      margin-bottom: 0;
    }
  }
}
